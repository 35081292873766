import { Component, OnInit } from '@angular/core';
import { BusinessInfoService } from '../services/business-info/business-info.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { CommonFunctions } from '../Utilities/CommonFunctions';
import { AppSettings } from '../Constant/AppSettings';
import { UtilsService } from '../services/utils/utils.service';
import { SubscriptionService } from "../services/subscription/subscription.service";

@Component({
  selector: 'app-configuration-wizard',
  templateUrl: './configuration-wizard.component.html',
  styleUrls: ['./configuration-wizard.component.css']
})
export class ConfigurationWizardComponent implements OnInit {

  items: MenuItem[] = [{ label: 'Configuration Wizard' }];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/home' };
  public innerWidth: any;
  isLoading: boolean = false;
  isLoadingUrl: boolean = false;
  disabled: boolean = false;
  listconfigurationwizard: any[] = [];
  subscriptionType: string = CommonFunctions.getBusinessInfo().subscriptionType == null ? 'FREE FOREVER' : CommonFunctions.getBusinessInfo().subscriptionType;
  subscriptionId: number = 0;
  availableWizard: any[] = [];
  selectedValues: string[] = [];
  showButton: boolean = true;
  businessStatus: boolean = false;
  isActive : boolean = false;
  showMore : boolean = false;
  arrayList: any[] = [];
  displayExtraDetails: boolean = false;
  headerName: string = '';
  availableExtraWizard: any[] = [];
  selectedExtraValues: string[] = [];
  showExtraButton: boolean = true;
  extraSelectedId: string = '';
  stateDate: string = CommonFunctions.getBusinessInfo().stateDate;
  exitingConfigurationWizard: string = CommonFunctions.getBusinessInfo().configurationWizard;
  exitingConfigurationWizardExtra: string = CommonFunctions.getBusinessInfo().configurationWizardExtra;
  showUpdateBtn : boolean = false;
  bubbleHelpText: string = '';
  bubbleHeaderName: string = '';
  displayBubbleHelpText: boolean = false;
  IndexValue:string = '';
  showExpand: boolean = false;
  disabledItems: { [key: number]: number[] } = {
    28: [2799, 2800, 2801], // Subscription 28 disables these
    29: [2800], // Subscription 29 disables this
  };

  constructor(
    private businessInfoService: BusinessInfoService,
    private messageService: MessageService,
    public utils: UtilsService,
    public subscriptionService: SubscriptionService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    console.log(this.stateDate);
    if(this.subscriptionType == "FREE FOREVER"){
      this.subscriptionId = 28;
    }else if(this.subscriptionType == "BASIC"){
      this.subscriptionId = 29;        
    }else if(this.subscriptionType == "STANDARD"){
      this.subscriptionId = 30;
    }
    
    this.innerWidth = window.innerWidth;
    this.businessInfoService.getConfigurationWizard().subscribe(
      (response) => {
        
        var json = JSON.parse(JSON.stringify(response));
        console.log('Get Configuration Wizard State Response --->> ' + JSON.stringify(json));  
        console.log('Status --->> ' + json.response.status);      
        
        json.data.forEach(async (element:any, index:any) => {
          let show:any = false;
          element.configurationWizardIgnore.forEach((element:any) => {
            if(element.subscriptionId == this.subscriptionId){
              show =  true;
            }
          });
          element['show'] = show;
          if(show == false){
            if(element.required == 'true'){
              this.availableWizard.push(element.id.toString());
            }
          }
          element.genId = index;
          this.listconfigurationwizard.push(element);
        });
        
        if(CommonFunctions.getBusinessInfo().state == "online"){
          this.businessStatus = true;
          let existing:any = [];
          if(this.exitingConfigurationWizard != null){
            existing = this.exitingConfigurationWizard.split(',');
          }
          existing.forEach((ele:any)=>{
            this.listconfigurationwizard.forEach(element => {
              if(element.id == ele){
                if(element.required == "true"){
                  element.show = false;
                }
              }
              if(element.configurationWizardId == "CONWIZ16"){
                if(this.selectedExtraValues.length  == 0){
                  if(element.required == "true"){
                    element.configurationWizardExtra.forEach((ext: any) =>{
                      this.selectedExtraValues.push(ext.id.toString());
                    });
                  }
                }
              }
            });
            this.selectedValues.push(ele.toString());
          });
        }else{
          
          let existing:any = [];
          if(this.exitingConfigurationWizard != null){
            existing = this.exitingConfigurationWizard.split(',');
          }

          existing.forEach((ele:any)=>{
            this.listconfigurationwizard.forEach(element => {
              if(ele == element.id && element.configurationWizardId == "CONWIZ16"){
                if(this.selectedExtraValues.length  == 0){
                  if(element.required == "true"){
                    element.configurationWizardExtra.forEach((ext: any) =>{
                      this.selectedExtraValues.push(ext.id.toString());
                    });
                  }
                }
              }
            });
            this.selectedValues.push(ele.toString());
          });

          let existingExtra:any = [];
          if(this.exitingConfigurationWizardExtra != null){
            existingExtra = this.exitingConfigurationWizardExtra.split(',');
          }

          existingExtra.forEach((eleExtra:any)=>{
            this.selectedExtraValues.push(eleExtra.toString());
          });

          if(this.multipleExist(this.selectedValues, this.availableWizard)){
            this.showButton = false;
          }else{ 
            this.showButton = true;
          }

        }
        this.reloadComponent();

        // let newArray = [];
        // for (let index = 0; index < 10; index++) {
        //   this.listconfigurationwizard[index]['genId'] = index;
        //   newArray.push(this.listconfigurationwizard[index]);    
        //   this.listconfigurationwizard[index+10]['genId'] = index+10;
        //   newArray.push(this.listconfigurationwizard[index+10]);
        // }
        // this.listconfigurationwizard = newArray;
    },(error) => {
      console.log(error);
      this.showErrorMessage(AppSettings.ERROR_MSG)
      this.hideProgressBar();
    });
  }
  
  updateState(isActive: boolean) {
    let state = '';
    if (isActive) {
      state = 'online';
    } else {
      state = 'offline';
    }
    console.log('state:', state)

    const serviceData = {
      configurationWizard: this.selectedValues.toString(),
      configurationWizardExtra: this.selectedExtraValues.toString(),
    }
    var data = { data: JSON.parse(JSON.stringify(serviceData)) };
    this.businessInfoService.updateState(CommonFunctions.getBusinessId(), state, data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Update State Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          this.showSuccessMessage(json.response.status, json.response.displayMessage);
          this.getBusinessInfo();
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
      },
      (error) => {
        console.log(error);
        this.hideProgressBar()
        this.showErrorMessage(AppSettings.ERROR_MSG)
      }
    );
  }

  getBusinessInfo() {
    this.businessInfoService.getBusinessInfo(CommonFunctions.getBusinessId()).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Business info response --> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          if (json.data != '' && json.data != 'null' && json.data != null) {
            CommonFunctions.setBusinessInfo(json.data);
            this.stateDate = json.data.stateDate;
            if(json.data.state == "online"){
              this.businessStatus = true;
              // this.SelectAll();
              let existing = json.data.configurationWizard.split(',');
              this.exitingConfigurationWizard = json.data.configurationWizard.split(',');
              existing.forEach((ele:any)=>{
                this.listconfigurationwizard.forEach(element => {
                  if(element.id == ele){
                    if(element.required == "true"){                  
                      element.show = false;
                    }
                  }
                });
                this.selectedValues.push(ele.toString());
              });
            }
            // else{
            //   this.businessStatus = false;
            //   this.selectedValues = [];
            // }
            this.reloadComponent();
          }
        } else {
          this.showErrorMessage(json.response.displayMessage)

        }
      },
      (error) => {
        console.log(error);
        this.showErrorMessage(AppSettings.ERROR_MSG)
        this.hideProgressBar();
      }
    );
  }

  showErrorMessage(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  showSuccessMessage(status: string, msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'success',
      summary: status,
      detail: msg,
    });
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

  showProgressBar() {
    this.messageService.clear();
    this.isLoading = true;
    this.disabled = true;
  }

  get state(): string {
    return this.utils.state;
  }

  onChangeSelect(autoIndex:any, arrayList:any, headerName:any, id: any){
    if(arrayList.length > 0){

      this.arrayList = arrayList;
      this.headerName = autoIndex+1 +". "+ headerName;
      if(this.availableExtraWizard.length != 0){
        if(this.selectedExtraValues.sort().join(',') != this.availableExtraWizard.sort().join(',')){
          this.selectedValues = this.selectedValues.filter(function(elem){ return elem != id; });
        }
      }else{
        this.selectedValues = this.selectedValues.filter(function(elem){ return elem != id; });
      }
      
      this.availableExtraWizard = [];
      arrayList.forEach((element:any) => {
        this.availableExtraWizard.push(element.id);
      });
      this.extraSelectedId = id;
      
      if(!this.showExtraButton){
        this.selectedExtraValues = [];
        this.showExtraButton = true;
      }
      this.IndexValue = autoIndex;
      this.showExpand = true;
    }

    if(this.multipleExist(this.selectedValues, this.availableWizard)){
      this.showButton = false;
    }else{ 
      this.showButton = true;
    }

    if(this.businessStatus){
      if(this.exitingConfigurationWizard != null){
        if(this.exitingConfigurationWizard.length != this.selectedValues.length){
          this.showUpdateBtn = true;
        }else{
          this.showUpdateBtn = false;
        }
      }
    }
    if(arrayList.length == 0){
      this.UpdateBusinessConfigurationWizard();
    }
  }

  multipleExist(arr:any, values:any) {
    return values.every((value:any) => {
      return arr.includes(value);
    });
  }   


  SelectAll(){
    this.selectedValues = [];
    this.availableWizard.forEach((element:any)=>{
      this.selectedValues.push(element.toString())
    });
    // this.onChangeSelect();
  }

  confirmEnableDisableDialog(isActive: boolean) {
    this.messageService.clear();
    this.confirmationService.confirm({
      message:'Are you sure you want to go online? Your customers will be able to connect to you',
      header:'Online Confirmation',
      icon:'pi pi-check-circle',
      accept: () => {
        this.updateState(!this.businessStatus);
      }
    });
  }

  updateConfirm() {
    this.messageService.clear();
    this.confirmationService.confirm({
      message:'Are you sure you want to update?',
      header: 'Update Confirmation',
      icon: 'pi pi-times-circle',
      accept: () => {
        this.UpdateBusinessConfigurationWizard();
      }
    });
  }

  UpdateBusinessConfigurationWizard(){
    this.selectedValues = this.selectedValues.filter((el, i, a) => i === a.indexOf(el));
    this.selectedExtraValues = this.selectedExtraValues.filter((el, i, a) => i === a.indexOf(el));

    if(this.selectedExtraValues.sort().join(',') !== this.availableExtraWizard.sort().join(',')){
      let id = this.extraSelectedId;
      this.selectedValues = this.selectedValues.filter(function(elem){ return elem != id; });
    }

    const serviceData = {
      configurationWizard: this.selectedValues.toString(),
      configurationWizardExtra: this.selectedExtraValues.toString(),
    }
    var data = { data: JSON.parse(JSON.stringify(serviceData)) };

    this.businessInfoService.updateBusinessConfigurationWizard(CommonFunctions.getBusinessId(), data).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Update State Response --->> ' + JSON.stringify(json));
        if (json.response.status == 'SUCCESS') {
          // this.showSuccessMessage(json.response.status, json.response.displayMessage);
          this.getBusinessInfo();
        } else {
          this.showErrorMessage(json.response.displayMessage)
        }
      },
      (error) => {
        console.log(error);
        this.hideProgressBar()
        this.showErrorMessage(AppSettings.ERROR_MSG)
      }
    );
  }

  reloadComponent() {
    this.utils.onReloadProfileClick(true);
  }

  onChangeExtraSelect(){
    // if(this.selectedExtraValues.sort().join(',') === this.availableExtraWizard.sort().join(',')){
    //   this.showExtraButton = false;
    // }else{ 
    //   this.showExtraButton = true;
    // }

    if(this.selectedExtraValues.sort().join(',') === this.availableExtraWizard.sort().join(',')){
      this.confirmExtraSelection();
    }else{
      let id = this.extraSelectedId;
      this.selectedValues = this.selectedValues.filter(function(elem){ return elem != id; });
      this.showButton = true;
    }
    this.UpdateBusinessConfigurationWizard();
  }

  confirmExtraSelection(){
    let freshArray = this.selectedValues;
    this.selectedValues = [];
    freshArray.forEach((ele)=>{
      this.selectedValues.push(ele);
    });    
    this.selectedValues.push(this.extraSelectedId.toString());
    // this.displayExtraDetails = !this.displayExtraDetails;

    if(this.multipleExist(this.selectedValues, this.availableWizard)){
      this.showButton = false;
    }else{ 
      this.showButton = true;
    }
    this.UpdateBusinessConfigurationWizard();
  }

  ShowBubbleHelpText(autoIndex:any, description:any, helpText:any){
    this.bubbleHeaderName = autoIndex+1 +". "+description;
    this.bubbleHelpText = helpText;
    this.displayBubbleHelpText = true;
  }

  expandDiv(autoIndex:any, arrayList:any, id:any){
    if(arrayList.length > 0){

      this.arrayList = arrayList;
      
      this.availableExtraWizard = [];
      arrayList.forEach((element:any) => {
        if(!this.shouldDisableCheckbox(element.id)){
          this.availableExtraWizard.push(element.id);
        }
      });
      this.extraSelectedId = id;
  
    }
    this.IndexValue =autoIndex;
    this.showExpand = !this.showExpand;
  }

  shouldDisableCheckbox(id: number): boolean {
    return this.disabledItems[this.subscriptionId]?.includes(id) ?? false;
  }
}
