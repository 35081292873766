export class Thread {
    message_channel_type?: string;
    sender_id?: string;
    sender_image?: string;
    sender_name?: string;
    sender_type?: string;
    text?: string;
    timestamp?: any;
    recepient_id?: string;
    attachments?: any[];
    formattedTime?: any;
}
