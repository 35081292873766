<div>
    <p-scrollPanel [style]="{width: '100%', height: '332px'}" #sc>
        <ol *ngIf="messageList.length > 0" class="discussion p-d-flex p-flex-column" style="padding: 2px;">
            <li *ngFor="let message of messageList; let i = index "
                [ngClass]="(message.id == businessId)?'self':'other'">
                <div *ngIf="i==0" class="messages_date">
                    {{message.timeTag}}
                </div>
                <div *ngIf="i>0 && message.timeTag != messageList[i-1].timeTag" class="messages_date">
                    {{message.timeTag}}
                </div>
                <div class="message" *ngFor="let msg of message?.subMsg; let j = index ">
                    <!-- <div class="message_name">{{msg.timestamp?.toDate() |
                        date:'h:mm a'}}</div> -->
                    <!-- <i [ngClass]="msg.message_channel_type=='sms'?'fa fa-comment-o':'fa fa-whatsapp'"
                    style="font-size: 1rem;"></i> -->
                    <div class="message_text" [ngStyle]="colorCode ? {'background-color': colorCode} : {'background-color': '#ebedeb'}">
                        <!-- {{ msg.text }} Comment for normal to string to HTML string render based on quill -->
                        <!-- <quill-view-html [content]="msg.text"></quill-view-html> -->
                        <div [innerHTML]="msg.text | safeHtml"></div>
                        <div class="attachments" *ngFor="let attachment of msg?.attachments">
                            <a [href]="attachment.url" target="_blank">{{attachment.name}}</a>
                        </div>
                        <!-- <div class="msg_time">{{msg.timestamp?.toDate() |
                            date:'h:mm a'}}</div> -->
                        <div class="msg_time">{{ msg.formattedTime }}</div>
                    </div>
                    <div *ngIf="message.id != businessId" class="message_avatar">
                        <!-- <img *ngIf="message.id == user_id; else avatar" src="assets/images/chatstasy_circle.png"
                        alt="">
                    <ng-template #avatar>
                    </ng-template> -->
                        <p-avatar [label]="msg.sender_name.charAt(0)" shape="circle"
                            [style]="{height: '29px', width: '29px'}">
                        </p-avatar>
                    </div>
                    <!-- <div class="message_sent_by">sent via {{msg.message_channel_type}}</div> -->

                </div>
            </li>

            <!-- <li *ngFor="let thread of threads; let i = index "
                    [ngClass]="(thread.sender_id == user_id)?'self':'other'">

                    <div class="message">
                        <div class="message_name">{{thread.timestamp.toDate() |
                            date:'h:mm a'}}</div>
                        <div class="message_text"> {{ thread.text }}</div>
                        <div class="message_avatar"><img src="assets/images/avatar-7.jpg" alt=""></div>
                        <div style="color: #8e8e93;
                            font-size: 10px; text-align: right; margin-right: 49px;">{{thread.timestamp.toDate() |
                            date:'h:mm a'}}</div>
                    </div>
                </li> -->
        </ol>
        <div *ngIf="messageList.length == 0" class="room_no_message">
            <h1 style="
                justify-content: center;
                align-self: center;">No Messages</h1>
        </div>
    </p-scrollPanel>
</div>