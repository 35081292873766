import { Component, OnInit } from '@angular/core';
import { ClientSegmentationService } from 'src/app/services/client-segmentation/client-segmentation.service';
import { ReportService } from 'src/app/services/report/report.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { CommonFunctions } from '../Utilities/CommonFunctions';
import * as XLSX from 'xlsx';

interface Client {
  clientName: string;
  servicesUsed: string[];
  totalRevenue: number;
}

interface Clients {
  id: number;
  name: string;
}

@Component({
  selector: 'app-report-client-engagement-report',
  templateUrl: './client-engagement-report.component.html',
  styleUrls: ['./client-engagement-report.component.css']
})
export class ClientEngagementReportComponent implements OnInit {

  clientSegments: Clients[] = [];

  filteredClientEngagement: Client[] = [];

  selectedDurationClientEngagement = 'YTD';
  startDateClientEngagement: string | undefined;
  endDateClientEngagement: string | undefined;

  
  startDate: string | undefined;
  endDate: string | undefined;

  selectedClientSegmentClient: string = 'All';

  isReportLoading: boolean = false;

  sortField: string = 'clientName';
  sortOrder: number = 1;

  reportDurationOptions = [
    { id: 'All', name: 'All' },
    { id: 'YTD', name: 'Year to Date (YTD)' },
    { id: 'custom', name: 'Date Range' }
  ];  

  constructor(
    public utils: UtilsService,
    private reportService: ReportService,
    private clientSegmentService: ClientSegmentationService
  ) { }

  ngOnInit(): void {
    this.getClientSegments();
    this.fetchClientEngagementData(); 
  }

  get state(): string {
    return this.utils.state;
  }

  getClientSegments() {
    this.clientSegmentService.getClientSegmentationList().subscribe(
      (response: any) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log(json.data);
        this.clientSegments = json.data;
        this.clientSegments = [{ id: 0, name: 'All' }, ...this.clientSegments];
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  convertDate(dateString: string | undefined): string | undefined {
    if(dateString != undefined){
      const dateObject = new Date(dateString);
      const formattedDate = dateObject.getFullYear() + '-' + 
                        (dateObject.getMonth() + 1).toString().padStart(2, '0') + '-' + 
                        dateObject.getDate().toString().padStart(2, '0');
      return formattedDate;
    }
    return undefined;
  }

  fetchClientEngagementData() {
    this.isReportLoading = true;
    if(this.selectedDurationClientEngagement === 'custom'){
      this.startDate = this.convertDate(this.startDateClientEngagement);
      this.endDate = this.convertDate(this.endDateClientEngagement);
    }else if(this.selectedDurationClientEngagement === 'All'){
      this.startDate = this.convertDate('2010-01-01');

      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');

      this.endDate = this.convertDate(`${year}-${month}-${day}`);
    }
    
    this.reportService
      .getClientEngagementReport(
        this.selectedClientSegmentClient,
        this.selectedDurationClientEngagement,
        this.startDate,
        this.endDate
      )
      .subscribe(
        (response: any) => {
          var json = JSON.parse(JSON.stringify(response));
          this.filteredClientEngagement = json.data; 
          this.isReportLoading = false;
        },
        (error: any) => {
          console.error(error);
          this.isReportLoading = false;
        }
      );
  }

  onClientSegmentClientChange() {
    this.fetchClientEngagementData();
  }

  onReportDurationChange(event: any): void {
    if (this.selectedDurationClientEngagement === 'YTD') {
      setTimeout(() => {
        this.fetchClientEngagementData();
        this.startDateClientEngagement= undefined;
        this.endDateClientEngagement= undefined;
      }, 0);
    }
  }

  printPage(id: any): void {
    const printContents = document.getElementById(id)?.innerHTML;
    const originalContents = document.body.innerHTML;

    if (printContents) {
        const printContainer = document.createElement('div');
        printContainer.id = 'print-container';
        printContainer.style.display = 'none';
        printContainer.innerHTML = printContents;
        document.body.appendChild(printContainer);

        const children = Array.from(document.body.children);
        children.forEach(child => {
            if (child.id !== 'print-container') {
                (child as HTMLElement).style.display = 'none';
            }
        });

        printContainer.style.display = 'block';
        window.print();

        children.forEach(child => {
            if (child.id !== 'print-container') {
                (child as HTMLElement).style.display = '';
            }
        });

        printContainer.remove();
    }
  }

  exportTableToXLSX() {
    const data = this.filteredClientEngagement;

    const headers = ['Client Name', 'List of Services Used', 'Revenue'];
  
    const rows = data.map(item => {
      return [
        item.clientName || '',
        Array.isArray(item.servicesUsed) ? item.servicesUsed.join(', ') : item.servicesUsed || '',
        item.totalRevenue ? `$ ${item.totalRevenue}` : ''
      ];
    });

    let xlsContent = `
      <table>
        <thead>
          <tr>
            <th style="text-align: center; border: 1px solid #000; width: 300px;">Client Name</th>
            <th style="text-align: center; border: 1px solid #000; width: 600px;">List of Services Used</th>
            <th style="text-align: center; border: 1px solid #000; width: 150px;">Revenue</th>
          </tr>
        </thead>
        <tbody>
    `;
  
    rows.forEach(row => {
      xlsContent += `
        <tr>
          <td style="text-align: left; vertical-align: middle; border: 1px solid #000">${row[0]}</td>
          <td style="text-align: left; border: 1px solid #000">${row[1]}</td>
          <td style="text-align: right; vertical-align: middle; border: 1px solid #000">${row[2]}</td>
        </tr>
      `;
    });
  
    xlsContent += `
        </tbody>
      </table>
    `;
  
    const blob = new Blob([xlsContent], { type: 'application/vnd.ms-excel' });
    const url = URL.createObjectURL(blob);

    const today = new Date();
    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);
    const hours = ('0' + today.getHours()).slice(-2);
    const minutes = ('0' + today.getMinutes()).slice(-2);
    const fileName = `${CommonFunctions.getBusinessInfo().name}-Client Engagement Report-${year}${month}${day}-${hours}${minutes}.xls`;

    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}