import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppointmentService } from '../services/appointment/appointment.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppSettings } from '../Constant/AppSettings';
import { environment } from 'src/environments/environment.prod';
import { CommonFunctions } from '../Utilities/CommonFunctions';

@Component({
  selector: 'app-appointment-confirmation',
  templateUrl: './appointment-confirmation.component.html',
  styleUrls: ['./appointment-confirmation.component.css']
})
export class AppointmentConfirmationComponent implements OnInit {

  disabled: boolean = true;
  isLoading: boolean = false;
  statusInfo: string = "pending";
  appointmentDetails: string = '';
  businessInfo: any;
  businessLogoUrl = '';
  alreadyConfirm: string = 'null';
  dialogWidth: string | undefined;

  constructor(
    private router: Router,
    private appointmentService: AppointmentService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.getAppointmentDetails(this.router.url.split('?')[0].split('/').pop());
    this.setDialogWidth();
    window.addEventListener('resize', this.setDialogWidth.bind(this));
  }

  setDialogWidth(): void {
    if (window.innerWidth <= 768) {
      this.dialogWidth = '90vw'; // Mobile view
    } else {
      this.dialogWidth = '45vw'; // Default view
    }
  }

  confirmAppointment(id:any){
    this.isLoading = true;
    this.appointmentService.confirmAppointmentPayment(id, true).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.statusInfo = 'success';
          this.showSuccessMessageWithTimeOut('confirm');
          this.showSuccessMessage(json.response.message)
        } else {
          this.statusInfo = 'error';
          this.showErrorToastMsg(json.response.message)
        }
        this.hideProgressBar()
        console.log('Cancel Appointment Response --->> ' + JSON.stringify(json));
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
      }
    );
  }

  getAppointmentDetails(id:any){
    console.log('Appointment ID --->> ' + JSON.stringify(id));
    this.appointmentService.getAppointmentDetailsByIDWithTemplate(id).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        console.log('Add Appointment Post Response --->> ' + JSON.stringify(json));
        console.log('Status --->> ' + json.response.status);
        if (json.response.status == 'SUCCESS') {
          this.appointmentDetails = json.data.template;
          this.businessInfo = json.data.businessInfo;
          this.businessLogoUrl = environment.IMAGE_BASE_URL + json.data.businessInfo.logo;
          this.alreadyConfirm = json.data.status;
        } else {
          this.alreadyConfirm = 'true';
          // this.showErrorToastMsg(json.response.displayMessage);
        }
        this.hideProgressBar();
      },
      (error) => {
        // console.log(error);
        // this.showErrorMessage(AppSettings.ERROR_MSG)
        this.alreadyConfirm = 'true';
        this.hideProgressBar();
      }
    );
  }

  confirmAppointmentConfirmDialog() {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Please confirm your appointment?',
      header: 'Confirm Confirmation',
      icon: 'pi pi-save',
      accept: () => {
        this.confirmAppointment(this.router.url.split('?')[0].split('/').pop());
      }
    });
  }

  cancelAppointmentConfirmDialog() {
    this.messageService.clear();
    this.confirmationService.confirm({
      message: 'Please confirm to cancel your appointment?',
      header: 'Cancel Confirmation',
      icon: 'pi pi-trash',
      accept: () => {
        this.cancelAppointment(this.router.url.split('?')[0].split('/').pop());
      }
    });
  }

  showSuccessMessageWithTimeOut(val:any){
    if(val == 'confirm'){
      this.alreadyConfirm = 'confirmSuccess';
    }
    if(val == 'cancel'){
      this.alreadyConfirm = 'cancelSuccess';
    }
    // setTimeout(() => {
    //   this.alreadyConfirm = 'true';
    // }, 3000);
    this.isLoading = false;
  }

  cancelAppointment(id:any){
    this.isLoading = true;
    this.appointmentService.cancelAppointment(id, true).subscribe(
      (response) => {
        var json = JSON.parse(JSON.stringify(response));
        if (json.response.status == 'SUCCESS') {
          this.statusInfo = 'success';
          this.showSuccessMessageWithTimeOut('cancel');
          this.showSuccessMessage(json.response.message)
        } else {
          this.statusInfo = 'error';
          this.showErrorToastMsg(json.response.message)
        }
        this.hideProgressBar()
        console.log('Cancel Appointment Response --->> ' + JSON.stringify(json));
      },
      (error) => {
        console.log(error);
        this.showErrorToastMsg(AppSettings.ERROR_MSG)
      }
    );

  }

  fileFailedToLoadBusinessLogo(event: any) {
    console.log(event)
    this.businessLogoUrl = 'assets/images/business_2.png'
  }

  showSuccessMessage(msg: string) {
    this.messageService.add({
      key: 'errMsg',
      severity: 'success',
      detail: msg,
    });
  }

  showErrorToastMsg(msg: string) {
    this.messageService.add({
      key: 'br',
      severity: 'error',
      detail: msg,
    });
  }

  hideProgressBar() {
    this.isLoading = false;
    this.disabled = false;
  }

}
