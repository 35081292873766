import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs'
import { Room } from 'src/app/models/room.model';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';

@Injectable({
  providedIn: 'root'
})
export class FirbaseMessagingService {
  messageCount = new BehaviorSubject('');
  notificationCount = new BehaviorSubject('');
  private dbPath = 'business_admin/chatrooms/' + CommonFunctions.getBusinessInfoId();
  private dbNotificationPath = 'business_admin/notifications/' + CommonFunctions.getBusinessInfoId();
  constructor(
    private db: AngularFirestore
  ) {
  }

  getAllUnreadRooms(): AngularFirestoreCollection<Room> {
    let itemsCollection = this.db.collection<Room>(this.dbPath, ref => ref
      .where('read_status', "==", "unread")
      .where('business_id', "==", CommonFunctions.getBusinessInfoId().toString())
      .where('recepient_id', "==", CommonFunctions.getBusinessId().toString())
    );
    return itemsCollection;
  }

  markRoomAsRead(id: any): Promise<void> {
    return this.db.collection(this.dbPath).doc(id).update({ read_status: 'read' });
  }

  getAllUnreadNotifications(): AngularFirestoreCollection<Notification> {
    let itemsCollection = this.db.collection<Notification>(this.dbNotificationPath, ref => ref.where('readStatus', "==", 'unread'));
    return itemsCollection;
  }
  getAllNotifications(): AngularFirestoreCollection<Notification> {
    let itemsCollection = this.db.collection<Notification>(this.dbNotificationPath, ref => ref.orderBy('timestamp', 'desc'));
    return itemsCollection;
  }

  getAllReadNotifications(): AngularFirestoreCollection<Notification> {
    let itemsCollection = this.db.collection<Notification>(this.dbNotificationPath, ref => ref.where('readStatus', "==", 'read'));
    return itemsCollection;
  }

  updateNotificationRead(id: any, object: any): Promise<void> {
    return this.db.collection(this.dbNotificationPath).doc(id).update(object);
  }

  setDbRef() {
    this.dbPath = 'business_admin/chatrooms/' + CommonFunctions.getBusinessInfoId();
    this.dbNotificationPath = 'business_admin/notifications/' + CommonFunctions.getBusinessInfoId();
  }

  removeNotification(id: any): Promise<void> {
    return this.db.doc(`${this.dbNotificationPath}/${id}`).delete()
      .then(() => console.log(`Notification with ID ${id} deleted successfully.`))
      .catch(error => console.error('Error deleting notification:', error));
  }

  deleteAllNotificationsByType(type:any): void {
    this.db.collection<Notification>(this.dbNotificationPath, ref => ref.where('readStatus', '==', type))
      .get()
      .subscribe(querySnapshot => {
        const batch = this.db.firestore.batch(); // Start a batch to delete multiple documents
  
        querySnapshot.forEach(doc => {
          batch.delete(doc.ref); // Add each document to the batch delete
        });
  
        // Commit the batch delete
        batch.commit().then(() => {
          console.log('All read notifications have been deleted');
        }).catch(error => {
          console.error('Error deleting read notifications:', error);
        });
      });
  }
  
}

export class Notification {
  referenceId?: string;
  readStatus?: string;
  notificationType?: string;
  title?: string;
  description?: string;
  timestamp?: any
}