<div [ngClass]="state=='open' ? 'main_content_open' : 'main_content'">
  <p-scrollPanel [style]="{width: '100%',height: '95vh','margin-top':'7px','padding-bottom':'70px'}" #sc>
    <app-message-credit-warning></app-message-credit-warning>
    <app-twillio-message></app-twillio-message>
    <div class="p-d-flex p-jc-between p-ai-center p-pt-3 p-pb-3">
      <div class="page_title">
        Manage Roles
      </div>
      <div>
        <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
      </div>
    </div>
    <div class="p-mb-5">
      <p-card>
        <div class="toolbar">
          <div class="p-grid p-ai-center">
            <div class="p-col p-md-6 p-lg-12 p-jc-end d-flex">
              <!-- *ngIf="getPrivilege('businessclient_role_create')" -->
              <button *ngIf="getPrivilege('manage_business_role')" pButton pRipple type="button" label="Add Role"
                class="p-button-raised" icon="pi pi-plus" style="font-size: 12px;"
                (click)="showHideModalDialog('add_role')" [disabled]="bussinesInfo?.subscriptionType != 'STANDARD'"></button>
            </div>
          </div>
        </div>
      </p-card>
      <p-card styleClass="p-mt-3">
        <div class="row form-group">
          <div class="col-sm-12">
            <p-table [columns]="cols" [value]="roles"
              styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped " [autoLayout]='true'
              [responsive]='true' [rowHover]="true">

              <ng-template pTemplate="header" let-columns>
                <tr style="font-size: small;">
                  <th *ngFor="let col of columns">
                    {{col.header}}
                  </th>
                  <!-- *ngIf="getPrivilege('businessclient_role_modify')" -->
                  <th *ngIf="getPrivilege('manage_business_role')">
                    Action
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-role let-columns="columns">
                <tr style="font-size: smaller;">
                  <td *ngFor="let col of columns">
                    <span class="p-column-title">{{col.header}}</span>
                    {{role[col.field]}}
                  </td>
                  <td *ngIf="getPrivilege('manage_business_role')" class="last_border">
                    <span class="p-column-title">Action</span>
                    <a *ngIf="getPrivilege('manage_business_role') && role.canEdit; else canEdit"
                      href="javascript:void(0);" data-toggle="modal" (click)="getRole(role,'edit_role')">
                      <i class="pi pi-pencil" style="font-size: 0.8rem;color:#007AFF"></i>
                    </a>
                    <ng-template #canEdit style="display:inline-block;">
                      <div *ngIf="getPrivilege('manage_business_role')" pTooltip="You cannot edit this role"
                        tooltipPosition="left" style="display:inline-block;">
                        <a href="javascript:void(0);" data-toggle="modal" class="disabledClass"
                          (click)="getRole(role,'edit_role')">
                          <i class="pi pi-pencil" style="font-size: 0.8rem"></i>
                        </a>
                      </div>
                    </ng-template>
                    &nbsp;
                    <!-- getPrivilege('businessclient_role_modify') && -->
                    <a *ngIf="role.canDelete; else canDelete" href="javascript:void(0);" data-toggle="modal"
                      (click)="confirmDeleteDialog(role)">
                      <i class="pi pi-trash" style="font-size: 0.8rem;color: #dc3545;"></i>
                    </a>
                    <ng-template #canDelete style="display:inline-block;">
                      <div pTooltip="You cannot delete this role" tooltipPosition="left" style="display:inline-block;">
                        <a href="javascript:void(0);" data-toggle="modal" class="disabledClass"
                          (click)="confirmDeleteDialog(role)">
                          <i class="pi pi-trash" style="font-size: 0.8rem"></i>
                        </a>
                      </div>
                    </ng-template>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                  <td [attr.colspan]="columns.length+1">
                    <div *ngIf="isLoading" class="alignCenter">
                      <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3">
                      </p-progressSpinner>
                    </div>
                    <p-messages key="errMsg" *ngIf="roles?.length == 0 && !isLoading" class="alignCenter">
                      <ng-template pTemplate>
                        <i class="pi pi-copy" style="font-size: 2rem"></i>
                        <div class="p-ml-2">NO DATA FOUND</div>
                      </ng-template>
                    </p-messages>
                  </td>
                </tr>
              </ng-template>
            </p-table>

          </div>
        </div>
      </p-card>
    </div>
  </p-scrollPanel>
</div>


<!-- ADD ROLE MODAL -->
<p-dialog #addDialog header="Add Role" [(visible)]="displayAddModal" [modal]="true" [baseZIndex]="10000"
  [draggable]="false" [resizable]="true" [maximizable]="true" styleClass="p-fluid" (onHide)="clearFormData()"
  (onMaximize)="onMaximizeAddRole(isMaximizeAddRole)" (onShow)="showDialogMaximized($event,addDialog)">
  <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>
  <form role="form" style="min-width: 60vw;" (ngSubmit)="addRole(addRoleForm)" #addRoleForm="ngForm">
    <div class="row">
      <!-- <div class="form-group col-md-6">
        <label>Role Name<span class="symbol required"></span></label>
        <input type="text" class="form-control border-black" name="roleName" placeholder="Enter Role Name"
          [(ngModel)]="roleName" (keypress)="keyPressAlphaNumericWithCharacters($event)" required />
      </div>
      <div class="form-group col-md-6">
        <label>Description<span class="symbol required"></span></label>
        <input type="text" class="form-control" name="roleDescription" placeholder=" Enter Description"
          [(ngModel)]="roleDescription" (keypress)="keyPressAlphaNumericWithCharacters($event)" required />
      </div> -->

      <div class="form-group col-md-6">
        <label>Role Name<span class="symbol required"></span></label>
        <input type="text" class="form-control border-black" name="roleName" placeholder="Enter Role Name"
          [(ngModel)]="roleName" required />
      </div>
      <div class="form-group col-md-6">
        <label>Description<span class="symbol required"></span></label>
        <input type="text" class="form-control" name="roleDescription" placeholder=" Enter Description"
          [(ngModel)]="roleDescription" required />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6 ">
        <label>Privileges<span class="symbol required"></span></label>
      </div>
    </div>
    <p-tree [value]="privileges" selectionMode="checkbox" [(selection)]="selectedPrivileges" scrollHeight="flex"
      [style]="{height: roleDialogMax}">
    </p-tree>
  </form>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" (click)="displayAddModal = false" label="Cancel"
      styleClass="p-button-danger p-button-text"></p-button>
    <p-button icon="pi pi-plus" (click)="addRole(addRoleForm)" label="Add" [disabled]="disabled"></p-button>
  </ng-template>
  <div *ngIf="isLoading" class="progressLayout">
    <div class="progressContainer">
      <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
    </div>
  </div>
</p-dialog>


<!-- EDIT ROLE MODAL -->
<p-dialog #editDialog header="Edit Role" [(visible)]="displayEditModal" [modal]="true" [baseZIndex]="10000"
  [draggable]="false" [resizable]="true" [maximizable]="true" styleClass="p-fluid"
  (onMaximize)="onMaximizeAddRole(isMaximizeAddRole)" (onShow)="showDialogMaximized($event,editDialog)">
  <p-messages key="errMsg" [style]="{
      'font-size': '12px'
    }"></p-messages>
  <form role="form" style="min-width: 60vw;" (ngSubmit)="editRole(editRoleForm)" #editRoleForm="ngForm">
    <div class="row">
      <!-- <div class="form-group col-md-6">
        <label>Role Name<span class="symbol required"></span></label>
        <input type="text" class="form-control border-black" name="roleName" placeholder="Enter Role Name"
          [(ngModel)]="roleName" (keypress)="keyPressAlphaNumericWithCharacters($event)" required />
      </div>
      <div class="form-group col-md-6">
        <label>Description<span class="symbol required"></span></label>
        <input type="text" class="form-control" name="roleDescription" placeholder=" Enter Description"
          [(ngModel)]="roleDescription" (keypress)="keyPressAlphaNumericWithCharacters($event)" required />
      </div> -->

      <div class="form-group col-md-6">
        <label>Role Name<span class="symbol required"></span></label>
        <input type="text" class="form-control border-black" name="roleName" placeholder="Enter Role Name"
          [(ngModel)]="roleName" required />
      </div>
      <div class="form-group col-md-6">
        <label>Description<span class="symbol required"></span></label>
        <input type="text" class="form-control" name="roleDescription" placeholder=" Enter Description"
          [(ngModel)]="roleDescription" required />
      </div>

    </div>
    <div class="row">
      <div class="form-group col-md-6 ">
        <label>Privileges<span class="symbol required"></span></label>
      </div>
    </div>
    <p-tree [value]="privileges" selectionMode="checkbox" [(selection)]="selectedPrivileges" scrollHeight="flex"
      [style]="{height: roleDialogMax}">
    </p-tree>
  </form>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" (click)="displayEditModal = false" label="Cancel"
      styleClass="p-button-danger p-button-text"></p-button>
    <p-button icon="pi pi-save" (click)="editRole(editRoleForm)" label="Save" [disabled]="disabled"></p-button>
  </ng-template>
  <div *ngIf="isLoading" class="progressLayout">
    <div class="progressContainer">
      <p-progressSpinner [style]="{ width: '40px', height: '40px' }" strokeWidth="3"></p-progressSpinner>
    </div>
  </div>
</p-dialog>

<!-- REMOVE USER MODAL -->
<p-confirmDialog [baseZIndex]="10000" [style]="{'min-width':'40vw'}" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>