import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { CommonFunctions } from 'src/app/Utilities/CommonFunctions';
import { environment } from 'src/environments/environment.prod';


@Injectable({
  providedIn: 'root'
})
export class ClientDataService {

  constructor(
    private http: HttpClient
  ) { }

  importExcelPreview(file: any) {
    const url = environment.API_URL + '/api/client/addBulkPreview';
    // console.log('Profile Service Data ---> ' + JSON.stringify(file));
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(url, formData, httpOptions).pipe(timeout(60000));
  }

  importClients(fileName: string) {
    const url = environment.API_URL + '/api/client/addBulk?successFile=' + fileName;
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  getDownloadTemplate() {
    const url = environment.API_URL + '/api/anonymous/cdn/clientData/downloadTemplate';
    window.open(url);
    console.log('client template url-->' + url)
  }

  getDownloadErrorFile(fileName: string) {
    const url = environment.API_URL + '/api/anonymous/cdn/clientData/downloadErrorFile?errorFileName=' + fileName;
    window.open(url);
    console.log('client error file url-->' + url)
  }

  getClientList(pageNumber: any, searchText: string, userType: string, sortData: any) {
    let params = new HttpParams().set('page', pageNumber)
    console.log(searchText);
    if (searchText != null && searchText != '') {
      params = params.set('search', searchText);
    }
    if (userType != null && userType != '') {
      params = params.set('userType', userType);
    }
    if (sortData != null && sortData != '') {
      params = params.set('sortBy', sortData.sortBy);
    }
    if (sortData != null && sortData != '') {
      params = params.set('sortDirection', sortData.sortDirection);
    }
    const url = environment.API_URL + '/api/client/list';
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
      params: params
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  getAllClientList(type:any) {
    let url = null
    if(type){
      url = environment.API_URL + '/api/client/listAll?type='+type;
    }else{
      url = environment.API_URL + '/api/client/listAll';
    }
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  addClient(clientData: any) {
    const url = environment.API_URL + '/api/client/add';
    // console.log('Business Hours edit Service Data ---> ' + JSON.stringify(businessHour));
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, clientData, httpOptions).pipe(timeout(60000));
  }

  editClient(client_id: string, clientData: any) {
    const url = environment.API_URL + '/api/client/' + client_id + '/edit';
    // console.log('Business Hours edit Service Data ---> ' + JSON.stringify(businessHour));
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, clientData, httpOptions).pipe(timeout(60000));
  }

  getSingleClient(client_id: string) {
    const url = environment.API_URL + '/api/client/' + client_id;
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  removeClient(client_id: string) {
    const url = environment.API_URL + '/api/client/' + client_id + '/delete';
    // console.log('Business Hours edit Service Data ---> ' + JSON.stringify(businessHour));
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.post(url, null, httpOptions).pipe(timeout(60000));
  }

  getClientById(client_id: string) {
    const url = environment.API_URL + '/api/client/' + client_id;
    // console.log('Business Hours edit Service Data ---> ' + JSON.stringify(businessHour));
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  getAllSubscribedClientList(type:any) {
    let url = null
    if(type){
      url = environment.API_URL + '/api/client/listAllSubscribedClients?type='+type;
    }else{
      url = environment.API_URL + '/api/client/listAllSubscribedClients';
    }
    const TOKEN = CommonFunctions.getToken();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + TOKEN,
        'Content-Type': 'application/json',
        'BusinessId': CommonFunctions.getBusinessId().toString()
      }),
    };
    return this.http.get(url, httpOptions).pipe(timeout(60000));
  }

  ExportExcelClientData() {
    const url = environment.API_URL + '/api/client/exportclientdata?businessId='+CommonFunctions.getBusinessId().toString();
    window.open(url);
    console.log('client template url-->' + url)
  }
}
